<template>
  <div class="liebian">
    <van-search
      v-model="keywords"
      show-action
      placeholder="请输入邀请码"
      @search="onSearch"
      shape="round"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
      <template #left>
        <div @click="onClickLeft" class="back_icon"><van-icon name="arrow-left" size="20" /></div>
      </template>
    </van-search>
    <div class="time_str">
      <van-cell :border="false" center>
        <template #title>
          <div @click="showDateCheck">日期区间：</div>
        </template>
        <template #default>
          <div @click="showDateCheck">{{timestr}}</div>
        </template>
        <template #right-icon>
          <van-button size="mini" @click="allClick" type="danger">全部</van-button>
        </template>
      </van-cell>
      <van-calendar v-model="showTime" :max-date="endDate" :default-date="timeArr" :min-date="startDate" type="range" @confirm="onTimeConfirm" />
    </div>
    <div class="static">
      <van-grid>
        <van-grid-item text="直接CEO">
          <template #icon>
            <div>{{tongji.ceo}}</div>
          </template>
        </van-grid-item>
        <van-grid-item text="直接顶级">
          <template #icon>
            <div>{{tongji.dj}}</div>
          </template>
        </van-grid-item>
        <van-grid-item text="直接特级">
          <template #icon>
            <div>{{tongji.tj}}</div>
          </template>
        </van-grid-item>
        <van-grid-item text="直接普通">
          <template #icon>
            <div>{{tongji.pt}}</div>
          </template>
        </van-grid-item>
        <van-grid-item text="间接CEO">
          <template #icon>
            <div>{{tongji.ceo2}}</div>
          </template>
        </van-grid-item>
        <van-grid-item text="间接顶级">
          <template #icon>
            <div>{{tongji.dj2}}</div>
          </template>
        </van-grid-item>
        <van-grid-item text="间接特级">
          <template #icon>
            <div>{{tongji.tj2}}</div>
          </template>
        </van-grid-item>
        <van-grid-item text="间接普通">
          <template #icon>
            <div>{{tongji.pt2}}</div>
          </template>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="main">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <div v-if="!nodata">
          <van-cell center :border="false" v-for="(item, index) in list" :key="index">
            <template #icon>
              <van-image
                round
                :src="item.avatar"
                width="60"
                height="60"
                lazy-load
              >
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
            </template>
            <template #title>
              <div class="row1 van-ellipsis">{{item.nickname}}</div>
              <div class="row1">
                <van-tag round color="#fffbe8" text-color="#ed6a0c" @click="copayAgentCode(item.agent_code)">{{item.agent_code}}</van-tag>
              </div>
            </template>
            <template #right-icon>
              <div class="van-cell__right-icon">
                <div class="row1">邀请粉丝数：{{item.nextNumfen}}</div>
                <div class="row1">邀请总人数：{{item.nextNum}}</div>
              </div>
            </template>
          </van-cell>
        </div>
        <div v-if="nodata">
          <van-empty description="暂无数据" />
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant';
export default {
  name: "Liebian",
  data () {
    return {
      list: [],
      tongji: {},
      refreshing: false,
      nodata: false,
      showTime: false,
      timestr: '',
      type: 0,
      startDate: new Date(2020, 8, 1),
      endDate: new Date(),
      timeArr: [],
      timeArr2: [],
      keywords: '',
      all: 0, // 默认不是全部会员
    }
  },
  mounted() {
    this.type = this.$route.query.type ? parseInt(this.$route.query.type) : 0;
    this.getDateStr(this.type);
    this.init();
  },
  methods: {
    init() {
      console.log('dddd');
    },
    getList() {
      let that = null;
      that = this;
      this.list = [];
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '加载中...',
      });
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + 'shopsmobile/member/getLiebian',
          that.$qs.stringify({
            date: that.timeArr2,
            keywords: that.keywords,
            all: that.all
          })
        )
        .then(res => {
          console.log(res);
          Toast.clear();
          that.refreshing = false;
          if (res.data.code == 100000) {
            that.list = res.data.data.list;
            that.tongji = res.data.data.tongji;
            that.startDate = new Date(new Date(res.data.data.tongji.start).getFullYear(), new Date(res.data.data.tongji.start).getMonth(), new Date(res.data.data.tongji.start).getDate())
            that.nodata = false;
            if(that.list.length <= 0) {
              that.nodata = true;
            }
          }else {
            that.nodata = true;
          }
        })
        .catch(err => {
          that.nodata = true;
          Toast.clear();
          console.log(err);
        })
    },
    onClickLeft() {
      this.$router.back();
    },
    onRefresh() {
      this.refreshing = true;
      console.log('onRefresh');
      this.getList();
    },
    // 复制邀请码
    copayAgentCode(code) {
      let _that = null;
      _that = this;
      _that.$copyText(code)
      .then(
        function(e) {
          _that.$toast("邀请码复制成功");
          console.log(e);
        },
        function(e) {
          console.log(e);
        }
      );
    },
    showDateCheck() {
      this.showTime = true;
    },
    onTimeConfirm(date) {
      console.log(date);
      let start = null;
      start = new Date(date[0])
      let end = null;
      end = new Date(date[1])
      let endY = '';
      let endM = '';
      let endD = '';
      let startY = '';
      let startM = '';
      let startD = '';
      startY = start.getFullYear();
      startM = start.getMonth()+1;
      startD = start.getDate();
      endY = end.getFullYear();
      endM = end.getMonth()+1;
      endD = end.getDate();
      if(startM<10) {
        startM = '0'+startM;
      }
      if(endM<10) {
        endM = '0'+endM;
      }
      if(startD<10) {
        startD = '0'+startD;
      }
      if(endD<10) {
        endD = '0'+endD;
      }
      this.timestr = startY+"/"+startM+"/"+startD+" - "+endY+"/"+endM+"/"+endD;
      this.showTime = false;
       this.timeArr = [start, end];
      this.timeArr2 = [startY+"/"+startM+"/"+startD, endY+"/"+endM+"/"+endD];
      this.getList();
    },
    // 获取日期
    getDateStr(type) {
      // 获取截止日期
      let timestr = '';
      timestr = new Date();
      this.endDate = new Date(timestr.getFullYear(),(timestr.getMonth()),(timestr.getDate()+1));
      let start = null;
      let end = null;
      let times = 0;
      times = new Date().getTime();
      if (type == 0) {
        start = new Date(times)
        times += 3600*24*1000
        end = new Date(times)
      } else if (type == 1) {
        end = new Date(times)
        times -= 3600*24*1000
        start = new Date(times)
      } else if (type == 2) {
        end = new Date(times)
        times -= 3600*24*7*1000
        start = new Date(times)
      } else if (type == 3) {
        end = new Date(times)
        times -= 3600*24*30*1000
        start = new Date(times)
      } else if (type == 4) {
        end = new Date(times)
        let monthStart = '';
        monthStart = (new Date().getFullYear())+'/'+(new Date().getMonth()+1)+'/01'
        start = new Date(monthStart)
      }
      let endY = '';
      let endM = '';
      let endD = '';
      let startY = '';
      let startM = '';
      let startD = '';
      startY = start.getFullYear();
      startM = start.getMonth()+1;
      startD = start.getDate();
      endY = end.getFullYear();
      endM = end.getMonth()+1;
      endD = end.getDate();
      if(startM<10) {
        startM = '0'+startM;
      }
      if(endM<10) {
        endM = '0'+endM;
      }
      if(startD<10) {
        startD = '0'+startD;
      }
      if(endD<10) {
        endD = '0'+endD;
      }
      this.timestr = startY+"/"+startM+"/"+startD+" - "+endY+"/"+endM+"/"+endD;
      this.timeArr = [start, end];
      this.timeArr2 = [startY+"/"+startM+"/"+startD, endY+"/"+endM+"/"+endD];
      this.getList();
    },
    allClick() {
      let start = null;
      let end = null;
      start = this.startDate
      end = this.endDate
      let endY = '';
      let endM = '';
      let endD = '';
      let startY = '';
      let startM = '';
      let startD = '';
      startY = start.getFullYear();
      startM = start.getMonth()+1;
      startD = start.getDate();
      endY = end.getFullYear();
      endM = end.getMonth()+1;
      endD = end.getDate();

      if(startM<10) {
        startM = '0'+startM;
      }
      if(endM<10) {
        endM = '0'+endM;
      }
      if(startD<10) {
        startD = '0'+startD;
      }
      if(endD<10) {
        endD = '0'+endD;
      }
      this.timestr = startY+"/"+startM+"/"+startD+" - "+endY+"/"+endM+"/"+endD;
      this.timeArr = [start, end];
      this.timeArr2 = [startY+"/"+startM+"/"+startD, endY+"/"+endM+"/"+endD];
      this.all = 1;
      this.getList();
    },
    onSearch() {
      if (this.keywords.trim().length <= 0) {
        this.$toast("请输入要搜索的邀请码");
        return false;
      }
      // this.allClick();
      this.getList();
    },
  }
}
</script>
<style lang="less">
.liebian {
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  background-color: #f8f8f8;
  .van-search--show-action {
    padding-left: 0;
  }
  .back_icon {
    padding: 0 10px;
    line-height: 20px;
    height: 20px;
    overflow: hidden;
  }
  .van-search__action {
    padding: 0 10px;
  }
  .time_str {
    .van-cell__value {
      flex: 1;
      text-align: left;
      margin-right: 5px;
      color: #333;
    }
  }
  .main {
    margin: 10px;
    overflow: hidden;
    .van-cell {
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 8px;
      overflow: hidden;
      .van-cell__title {
        flex: 1;
        text-align: left;
        margin-left: 8px;
        overflow: hidden;
      }
      .van-cell__right-icon {
        font-size: 12px;
        height: 100%;
      }
    }
  }
}
</style>